import { APP_URL, SOCIAL_AUTH_STATE, SOCIAL_AUTH_GOOGLE_CLIENT_ID, SOCIAL_AUTH_GOOGLE_URL_BASE, SOCIAL_AUTH_GOOGLE_SCOPES } from "@/common/config";
import INVRS from '../main';

const redirectPath = "/assets/pages/socialauth.html";
const redirectUrl = APP_URL + redirectPath;

const socialAccountTypes = { GOOGLE: "google" };

class SocialAuthServiceImpl {

  isInitialized = false;
  popup;
  socialAuthCompleteCallback;

  init() {
    if(!this.isInitialized){
      this.isInitialized = true;
      
      this.EventBus = INVRS.app.config.globalProperties.EventBus;
      this.EventBus.on('social-auth-complete', (code) => {
        this.socialAuthCompleteCallback(code);
        this.popup.close();
      });
    }
  }

  socialAuth(socialAccountType, socialAuthCompleteCallback) {
    this.socialAuthCompleteCallback = socialAuthCompleteCallback;

    let url;

    switch (socialAccountType) {
      case socialAccountTypes.GOOGLE:
        url = SOCIAL_AUTH_GOOGLE_URL_BASE
          + "?client_id=" + SOCIAL_AUTH_GOOGLE_CLIENT_ID
          + "&state=" + SOCIAL_AUTH_STATE
          + "&response_type=code"
          + "&scope=" + SOCIAL_AUTH_GOOGLE_SCOPES
          + "&redirect_uri=" + redirectUrl;
        break;
    }

    if (url) {
      this.openPopup(socialAccountType, url);
    }
  }

  openPopup(windowName, url) {
    const width = 600;
    const height = 600;
    // Center the pop-up based on parent window
    const y = window.top.outerHeight / 2 + window.top.screenY - (height / 2);
    const x = window.top.outerWidth / 2 + window.top.screenX - (width / 2);

    this.popup = window.open(url, windowName,
      `toolbar=no, location=no, directories=no, status=no, menubar=no, 
            scrollbars=no, resizable=no, copyhistory=no, width=${width}, height=${height}, top=${y}, left=${x}`);
  }

}

const SocialAuthService = new SocialAuthServiceImpl();
export default SocialAuthService;
<template>
<div class="signup-main-container">
    <header class="sign-up-header">
        <img src="../../public/Invrs-Logo-Green-Blue.png" class="nav-image" style="cursor: pointer" @click="goHome()" />
        <Button label="Sign In" class="p-button-text login-button" @click="goToLoginPage()" />
    </header>
    <LoadingSpinner :showLoading="showSpinner" style="width: 100%; height:100%; min-height: 100vh;" />
    
    <div v-show="readyToDisplay">
        <div class="signup-main-content" >
            <!-- <router-view> -->
                <div v-if="$route.path === '/signup/1'" class="signup-container-1">
                    <div class="social-button-container" v-if="!isNativeApp()">
                        <Button label="Sign Up Using Google" class="social-button" @click="socialSignUpClick('google')">
                            <img class="social-button-img" alt="Google Logo" src="../../public/google-logo.png" />
                            <span class="social-button-text">Sign up with Google</span>
                        </Button>
                    </div>
                    <template v-if="showSystemStatus">
                        <Message  severity="warn" :closable="false"><span v-html="systemStatus"></span></Message>
                    </template>
                    <template v-if="showOrientationWarning">
                        <Message  severity="warn" ><span>Based on your screen size you should change your device orientation to landscape mode.</span></Message>
                    </template>
                    <div class="sign-up-container">
                        <!-- <div class="sign-up-title-container">
                            <span class="sign-up-title">Welcome.</span>
                            <img src="/assets/images/login-signup-graphic.png" v-if="isMobile()"/>
                        </div> 
                        <span class="sign-up-subtext">Sign up and start your financial journey!</span>
                        -->
                        <div class="sign-up-title-container">
                            <span class="sign-up-title">Welcome.</span>
                            
                        </div>
                        <div class="sign-up-title-container">
                            <span class="sign-up-subtext">Sign up and start your financial journey!</span>
                        </div>
                        <section>
                            <div class="container">
                                
                                <div class="section-header">
                                
                                <span class="heading-secondary">Announcing the <img src="/assets/qi-logo.svg" width="100" style="vertical-align:bottom;"/> Summer Stock Showdown!</span>
                                <p class="heading-description">
                                    
                                    <small>July 22 - August 30<br>
                                $1,700 in prizes<br>
                                Free entry, full contest details <a target="_" :href="HOME+'/showdown-rules'">here.</a>
                                    </small>   
                                </p>
                                
                                </div>
                                
                            </div>
                            
                        </section>
                        <div class="input-container">
                            <div class="input-section">
                                <span :class="{'input-error': v$.email.$error }">
                                    <InputText placeholder="Email" id="email" type="text" v-model="email" @input="resetError('email')" @input.once="typingIntoField('email')" :disabled="activationToken && activationToken.sub" />
                                </span>
                                <template v-for="error of v$.email.$errors" :key="error.$uid">
                                    <span class="error-message">{{ error.$message }}</span>
                                </template>
                            </div>
                            <div class="input-section">
                                <span :class="{'input-error': v$.password.$error }">
                                    <Password placeholder="Password" id="password" v-model="password" toggleMask :mediumRegex="mediumPasswordRegex" :strongRegex="strongPasswordRegex" @input="resetError('password'), typingIntoField('password')">
                                        <template #footer v-if="!passwordIsLongEnough">
                                            <Divider />
                                        <p class="p-mt-2 p-text-bold" style="color: #32364e;">Requirements:</p>
                                            <ul class="p-pl-2 p-ml-2 p-mt-0">
                                                <li>At least one number</li>
                                                <li>Minimum 6 characters</li>
                                            </ul>
                                        </template>
                                    </Password>
                                </span>
                                <template v-for="error of v$.password.$errors" :key="error.$uid">
                                    <span class="error-message">{{ error.$message }}</span>
                                </template>
                            </div>
                        </div>
                        <div class="button-container">
                            <Button label="Next" class="next-button" @click="nextStep">Next</Button>
                        </div>
                    </div>
                </div>
                <div v-else-if="$route.path === '/signup/2' && !hideLastSection" class="signup-container-2">
                    <div class="sign-up-container">
                        <span class="sign-up-title-2">Select your username.</span>
                        <span class="sign-up-subtext" v-if="!isSocialSignUp">Add your name and username below.</span>
                        <div class="input-container">
                            <div class="input-section" v-if="!isSocialSignUp">
                                <span :class="{'input-error': v$.name.$error }">
                                    <!-- <InputText id="name" type="text" v-model="name" @input="resetError('name')" @input.once="typingIntoField('name')"/> -->
                                    <InputText placeholder="Name" id="name" type="text" v-model="name" @input="resetError('name')" @input.once="typingIntoField('name')" />
                                    <!-- <label for="name" class='input-label show-on-desktop'>Name</label> -->
                                </span>
                                <template v-for="error of v$.name.$errors" :key="error.$uid">
                                    <span class="error-message">{{ error.$message }}</span>
                                </template>
                            </div>
                            <div class="input-section">
                                <span :class="{'input-error': v$.username.$error }">
                                    <!-- <InputText v-if="!isMobile()" class="show-on-desktop" id="username" type="text" v-model="username" @input="resetError('username')" @input.once="typingIntoField('username')"/> -->
                                    <InputText placeholder="Username" id="username" type="text" v-model="username" @input="resetError('username')" @input.once="typingIntoField('username')" />
                                    <!-- <label for="username" class='input-label show-on-desktop'>Username</label> -->
                                </span>
                                <template v-for="error of v$.username.$errors" :key="error.$uid">
                                    <span class="error-message" v-if="!error.$pending">{{ error.$message }}</span>
                                </template>
                            </div>
                        </div>
                        <div class="button-container">
                            <vue-recaptcha
                                class="recaptcha-center"
                                v-if="!isSocialSignUp"
                                :siteKey="siteKey"
                                size="normal"
                                theme="light"
                                :tabindex="0"
                                @verify="recaptchaVerified"
                                @expire="recaptchaExpired"
                                @fail="recaptchaFailed"
                                ref="vueRecaptcha"
                                :style="captchaStyle"
                            >
                            </vue-recaptcha>
                            <Button label="Next" class="next-button" :disabled="disableNextButton" @click="nextStep">Next</Button>
                            <Button label="Back" class="back-button" @click="previousStep">Back</Button>
                        </div>
                    </div>
                </div>
                <div v-else-if="$route.path === '/signup/3'" class="signup-container-3">
                    <div class="sign-up-container">
                        <span class="sign-up-title-2">How experienced are you as an investor?</span>
                        <div class="tags-container">
                            <div v-for="(tag, i) in experienceLevelTags" :key="i">
                                <Button :label="tag.name" :class="{ 'tag-button': true, 'p-button-rounded': true, selected: tag.tagId == selectedExperienceLevelTag.tagId }" @click="selectExperienceLevel(tag)" />
                            </div>
                        </div>
                        <div class="button-container">
                            <Button label="Finish" class="finish-button" :disabled="disableFinishButton" :loading="submitting" @click="checkUserNameAvailable(confirmTerms)" />
                            <Button label="Back" class="back-button" @click="previousStep" />
                        </div>
                    </div>    
                </div>
            <!-- </router-view> -->

            <SignupSubmissionErrorModal ref="submissionErrorModal" @resubmit-username="resubmitUsername" />
                <Toast position="center" group="center" style="z-index:30000;" :autoZIndex="false" />
            <Dialog id="terms-dialog" :modal="true" header="Terms and Conditions" v-model:visible="displayTerms" :breakpoints="{ '960px': '95vw' }" :style="{ width: '30vw' }" class="terms-modal" :closable="false">
                <p>Please review the <a target="_" :href="getTermsUrl()">Terms and Conditions</a>.</p>
                <p>By clicking "Accept" you accept and agree to be bound by and comply with these terms and conditions.</p>
                <template #footer>
                    <Button label="Reject" class="action-button p-button-rounded terms-button reject" @click="rejectTerms" />
                    <Button label="Accept" class="action-button p-button-rounded terms-button" @click="acceptTerms" autofocus />
                </template>
            </Dialog>
            <Dialog
                id="signup-complete-dialog"
                :modal="true"
                header="Signup Complete"
                v-model:visible="displaySignupComplete"
                :breakpoints="{ '960px': '95vw' }"
                :style="{ width: '30vw' }"
                class="signup-complete-modal"
                :closable="false"
            >
                <p><strong>Welcome! We've successfully created your account.</strong></p>
                <p>Click OK to continue.</p>

                <template #footer>
                    <Button label="OK" class="action-button p-button-rounded signup-complete-button" @click="emailConfirmed" autofocus />
                </template>
            </Dialog>
        </div>
        <!-- <div class="signup-main-content" v-else>
            <div class="signup-container-1" style="text-align:center;line-height:30px;">
                <div>Sorry, we are currently not accepting new users.</div>
                <div style="width:100%;"></div>
                <div>You can <a :href="getWaitlistUrl()">join the waitlist</a> to secure your spot. If you are already on the waitlist, we will send you an email with a special signup link when it is your turn to signup.</div>
            </div>
        </div> -->
    </div>
</div>
</template>

<script>
import { defineAsyncComponent } from 'vue';
import {COOKIE_SHARED_FEED_POST_ID, COOKIE_SIGN_UP_CONTEST_ID, COOKIE_CONTEST_FAILED_REG_MESSAGE,COOKIE_COURSE_REG_CODE} from '@/common/constants';
import { HOME, APP_PAGE, RECAPTCHA_SITE_KEY } from '@/common/config';
//import SignupSubmissionErrorModal from '../components/errors/SignupSubmissionErrorModal';
import Password from 'primevue/password';
import Divider from 'primevue/divider';
import LoadingSpinner from '../components/common/LoadingSpinner.vue';

import LocalStorageService from '../service/LocalStorageService';
import UserService from '../service/UserService';
import AppService from '../service/AppService';
import SocialAuthService from '../service/SocialAuthService';


import CookieUtils from '../utilities/CookieUtils';
import CustomValidationUtils from '../utilities/CustomValidationUtils';
import BrowserUtils from '../utilities/BrowserUtils';
import StringUtils from '../utilities/StringUtils';

//import EventBus from '../event-bus';
import useVuelidate from '@vuelidate/core';
import { required, email, helpers, maxLength, minLength } from '@vuelidate/validators';
import Toast from 'primevue/toast';
import vueRecaptcha from 'vue3-recaptcha2';
import Dialog from 'primevue/dialog';
import Message from 'primevue/message';
import { createAvatar } from '@dicebear/core'; //eslint-disable-line

//import { adventurerNeutral,bigEars,bigSmile,miniavs,personas,notionists,avataaars   } from '@dicebear/collection'; //eslint-disable-line
import AdminService from '../service/AdminService';

import AnalyticsService from '../service/AnalyticsService';
//const avatarLibs = [adventurerNeutral,bigEars,bigSmile,miniavs,personas,notionists,avataaars];//eslint-disable-line

const experienceLevelTagCategory = 'Investment Categories';
const experienceLevelTagsData = [
    {
        tagId: 44,
        tagCategoryId: 2,
        name: "Beginner"
    },
    {
        tagId: 45,
        tagCategoryId: 2,
        name: "Intermediate"
    },
    {
        tagId: 46,
        tagCategoryId: 2,
        name: "Advanced"
    },
];

const requiredCustomMessage = helpers.withMessage('Field is required.', required);

export default {
    name: 'Signup',
    setup: () => ({ v$: useVuelidate() }),

    components: {
        Divider,
        Password,
        vueRecaptcha,
        SignupSubmissionErrorModal: defineAsyncComponent(() =>
            import('../components/errors/SignupSubmissionErrorModal')
            ),
        Toast,
        Dialog,
        LoadingSpinner,
        Message
    },


    data() {
        return {
            HOME,
            currentStep: 0,
            isSocialSignUp: false,
            experienceLevelTags: experienceLevelTagsData,

            // Used for populating submission package
            activationToken: null,
            socialAccountType: '',
            socialAuthCode: '',
            username: '',
            email: '',
            password: '',
            name: '',
            selectedExperienceLevelTag: { name: undefined },

            mediumPasswordRegex: '^(?=.*[0-9])(?=.{6,})',
            strongPasswordRegex: '^(?=.*[\\W|_])(?=.*[0-9])(?=.{8,})',

            firstTimeTypingName: false,
            firstTimeTypingUsername: false,
            firstTimeTypingPassword: false,
            firstTimeTypingEmail: false,

            verified: false,
            siteKey: RECAPTCHA_SITE_KEY,
            signupNonce: null,
            submitting: false,

            encodedActivationToken: null,
            
            readyToDisplay: false,

            displayTerms: false,
            displaySignupComplete: false,
            showSpinner: false,
            hideLastSection: false,

            inviteCode: null,
            systemStatus: null,
            showSystemStatus: false,
            showOrientationWarning: false,

        };
    },

    mounted() {
        this.showOrientationWarning = false;
        try {
            
            AdminService.getServerStatus().then(resp=> {
                //console.log('resp: ', resp)
                if( resp.data.status != 'OK') {
                    //console.log("resp.data.status = ["+ resp.data.status+"]")
                    this.systemStatus = resp.data.extMessage;
                    this.showSystemStatus = true;
                }
                else {
                    this.systemStatus = resp.data.extMessage;
                    this.showSystemStatus = false;
                }
            })
        }catch( error) {
            console.log("error getting server status... ignoring, Error was :", error);
            this.showSystemStatus = false;
        }

        const ROUTE_MAP = {
            '/signup/1': 0,
            '/signup/2': 1,
            '/signup/3': 2,
        };

        const ROUTE_NAME_MAP = {
            0: 'signup-step-1',
            1: 'signup-step-2',
            2: 'signup-step-3',
        };

        // If just getting onto the signup process from elsewhere
        if (this.$route.path === '/signup') {
            this.readyToDisplay = false;
            const inviteCode = this.$route.query.invite;
            const affiliateReferralId = this.$route.query.utm_source;
            const contestId = this.$route.query.contestId;
            const courseRegCode = this.$route.query.courseRegCode;
            

            //console.log('in signup mounted with affiliate['+affiliateReferralId+']');
            AppService.cleanUpApp(); // in case they logged in previously
            sessionStorage.clear();

            if( inviteCode && LocalStorageService.getGroupInviteCode() !== undefined){
                LocalStorageService.storeGroupInviteCode( inviteCode);
            }

            if (affiliateReferralId) {
                CookieUtils.setCookie('affiliate.referral.id', affiliateReferralId);
            }

            if (contestId) {
                CookieUtils.setCookie(COOKIE_SIGN_UP_CONTEST_ID, contestId);
                
                //console.log("setCookie COOKIE_SIGN_UP_CONTEST_ID", contestId);
            }
             if (courseRegCode) {
                CookieUtils.setCookie(COOKIE_COURSE_REG_CODE, courseRegCode);
                
                //console.log("setCookie COOKIE_SIGN_UP_CONTEST_ID", contestId);
            }
            this.readyToDisplay = true;

            this.$router.push({ path: '/signup/1', query: this.$route.query});
            // UserService.getSignupStatus().then(resp => {
            //     if (resp.data.status == 'enabled') {
            //         this.signupDisabled = false;
            //         this.readyToDisplay = true;

            //         this.$router.push({ path: '/signup/1', query: this.$route.query});
                
            //     } else {
            //         this.signupDisabled = true;
            //         this.readyToDisplay = true;
            //     }
            // });
        } else { // If on one of the steps of the signup process
            SocialAuthService.init();

           // this.signupDisabled = false;
            this.readyToDisplay = true;
            this.currentStep = ROUTE_MAP[this.$route.path];

            dataLayer.push({'event': ROUTE_NAME_MAP[this.currentStep]}); // eslint-disable-line

            //console.log(dataLayer) //eslint-disable-line

            // Reloading all of these data properties after refresh or going to a step since this component gets re-rendered each route it goes to
            this.socialAuthCode = sessionStorage.getItem('socialAuthCode');
            this.socialAccountType = sessionStorage.getItem('socialAccountType');
            if (this.socialAuthCode && this.socialAccountType) {
                this.isSocialSignUp = true;
            } else {
                this.socialAuthCode = '';
                sessionStorage.setItem('socialAuthCode', '');
                this.socialAccountType = '';
                sessionStorage.setItem('socialAccountType', '');
            }

            this.email = sessionStorage.getItem('email');
            this.password = sessionStorage.getItem('password');
            this.signupNonce = sessionStorage.getItem('signupNonce');
            this.username = sessionStorage.getItem('username');
            this.name = sessionStorage.getItem('name');

            if (sessionStorage.getItem('selectedExperienceLevelTag')) {
                this.selectedExperienceLevelTag = JSON.parse(sessionStorage.getItem('selectedExperienceLevelTag'));
            } 
            //console.log( this.selectedExperienceLevelTag);
        }
        
        if(  window.innerWidth > 760 && window.innerWidth < 991 ){
            this.$nextTick(() => {
                //this.$toast.add({severity: 'warn', summary: "Switch to landscape mode.", detail:"Based on your screen size you should change your device orientation to landscape mode.",  life:20000, group: 'center'});
                this.showOrientationWarning = true;
            })
            
        }
    },

    activated() {
        console.log('activating signup');
    },

  
    computed: {
        captchaStyle() {
            if (StringUtils.isNotEmpty(this.username) && StringUtils.isNotEmpty(this.name)) {
                return '';
            } else {
                return 'pointer-events:none;opacity:0.6;';
            }
        },

        disableNextButton() {
            if (this.isSocialSignUp) {
                return StringUtils.isNotEmpty(this.username) ? null : 'disabled';
            } else {
                return StringUtils.isNotEmpty(this.username) && StringUtils.isNotEmpty(this.name) && this.verified ? null : 'disabled';
            }
        },

        disableFinishButton() {
            return StringUtils.isNotEmpty(this.selectedExperienceLevelTag.name) ? null : 'disabled';
        },

        passwordIsLongEnough() {
            return this.password?.length >= 6;
        }
    },
    methods: {
        routeProcessor(step) {

            const map = {
                0: '/signup/1',
                1: '/signup/2',
                2: '/signup/3',
            };

            return this.$router.push({path: map[step], query: this.$route.query});
        },

        isMobile() {
            return BrowserUtils.isMobile();
        },

        isNativeApp() {
            return BrowserUtils.isNativeApp();
        },

        getWaitlistUrl() {
            return HOME + '/join-waitlist.html';
        },

        getFullName(analyst) {
            let fullName = analyst.firstName;
            if (analyst.middleName) {
                fullName = fullName + ' ' + analyst.middleName;
            }
            if (analyst.lastName) {
                fullName = fullName + ' ' + analyst.lastName;
            }
            return fullName;
        },
        getTermsUrl() {
            return HOME + '/terms.html';
        },
        goHome() {
            window.location.href = HOME;
        },
        goToLoginPage() {

            if (this.$route.query.contestId !== undefined) {
                CookieUtils.deleteCookie(COOKIE_SIGN_UP_CONTEST_ID);
                this.$router.push({ path: '/login', query: { contestId: this.$route.query.contestId} });

            }
            else if(this.$route.query.courseRegCode !== undefined) {
                CookieUtils.deleteCookie(COOKIE_COURSE_REG_CODE);
                this.$router.push({ path: '/login', query: { courseRegCode: this.$route.query.courseRegCode} });
            } else {
                this.$router.push({ path: '/login' });

            }
        },
        async checkUserNameAvailable(next) {
            //console.log('checking user name');
            await UserService.checkUserNameAvailable(this.username).then(res => {
                //console.log("check user name resp : " + JSON.stringify(res) );
                if (res.data.status === 'taken') {
                    const message = 'Username was taken. Please submit another one.';

                    this.$refs.submissionErrorModal.open(message);
                } else {
                    next();
                }
            });
        },

        async checkFullNameValid(next) {
            //console.log('checking full  name');
            await UserService.checkFullNameValid(this.name).then(res => {
                //console.log("check user name resp : " + JSON.stringify(res) );
                if (res.data.status === 'invalid') {
                    const message = 'Sorry the name you selected is either invalid or blocked. Please submit another one.';

                    this.$refs.submissionErrorModal.open(message);
                } else {
                    next();
                }
            });
        },

        confirmTerms() {
            this.displayTerms = true;
        },

        rejectTerms() {
            this.displayTerms = false;
        },

        acceptTerms() {
            this.displayTerms = false;
            this.submitPackage();
            this.showSpinner = true;
            this.hideLastSection = true;
        },

        getRandomInt(min, max) {
            min = Math.ceil(min);
            max = Math.floor(max);
            return Math.floor(Math.random() * (max - min) + min); // The maximum is exclusive and the minimum is inclusive
        },

        async generateAvatar(username) {
           // import { createAvatar } from '@dicebear/core'; //eslint-disable-line

            import( '@dicebear/collection').then( async ({ adventurerNeutral,bigEars,bigSmile,miniavs,personas,notionists,avataaars} ) => {
                const avatarLibs = [adventurerNeutral,bigEars,bigSmile,miniavs,personas,notionists,avataaars];//eslint-disable-line
                const lib = avatarLibs[this.getRandomInt(0,avatarLibs.length)]
                const avatar = createAvatar(lib, {
                    seed: username+Math.random(),
                  
                    });
                const png = await avatar.png();
                return await png.toDataUri();
            }); //eslint-disable-line}); //eslint-disable-line


            
        },

        async submitPackage() {
            this.submitting = true;

            // Submission data to be sent to the back end for account creation
            let data = {
                socialAuthCode: '',
                socialAccountType: '',
                username: '',
                fullName: {
                    first: '',
                    middle: '',
                    last: '',
                },
                image: {
                    name: '',
                    url: '',
                },
                email: '',
                password: '',
                signupNonce: null,
                selectedTags: {},
                experienceLevel: null,
                followedPeople: [],
                referringAnalystId: this.$route.query.ref,
                affiliateId: CookieUtils.getCookie('affiliate.referral.id') === "" ? null : CookieUtils.getCookie('affiliate.referral.id'),
                contestId: CookieUtils.getCookie(COOKIE_SIGN_UP_CONTEST_ID) === "" ? null : CookieUtils.getCookie(COOKIE_SIGN_UP_CONTEST_ID)
            };

            data.socialAuthCode = this.socialAuthCode;
            data.socialAccountType = this.socialAccountType;
            data.username = this.username;
            data.password = this.password;
            data.email = this.email;
            data.signupNonce = this.signupNonce;
            data.experienceLevel = this.selectedExperienceLevelTag.name;
            // try {
            //     data.image.url = await this.generateAvatar(data.email);
            // }catch( error ){
            //     console.log("Error generating avatar on signup ignoring: "+ error);
            // }

            if (!this.isSocialSignUp) {
                this.parseFullName(data);
            }

            data.selectedTags[experienceLevelTagCategory] = [this.selectedExperienceLevelTag];

            UserService.createAccount(data).then(resp => {
                if (resp.data.status == 'success') {
                    sessionStorage.clear();
                    CookieUtils.deleteCookie('affiliate.referral.id');
                    
                    if(resp.data.email){
                        this.email = resp.data.email;
                    }

                    if (resp.data.decodedContestId) {
                        CookieUtils.deleteCookie(COOKIE_SIGN_UP_CONTEST_ID); // Restore cookie with actual decoded contest id to use for direct navigation afterwards
                        CookieUtils.setCookie(COOKIE_SIGN_UP_CONTEST_ID, resp.data.decodedContestId);
                    }

                    if (resp.data.contestFailedRegMessage) {
                        CookieUtils.setCookie(COOKIE_CONTEST_FAILED_REG_MESSAGE, resp.data.contestFailedRegMessage);
                    }
                    try {
                        let sharedPostId = CookieUtils.getCookie(COOKIE_SHARED_FEED_POST_ID) === "" ? null : CookieUtils.getCookie(COOKIE_SHARED_FEED_POST_ID);

                       // EventBus.emit('track-signup', 
                       AnalyticsService.signup(
                            { 
                                email: this.email, 
                                username: this.username, 
                                experienceLevel: this.selectedExperienceLevelTag.name, 
                                referringPostId: sharedPostId,
                                affiliateCode: data.affiliateId 
                            });
                    } catch (err) {
                        return;
                    }

                    this.showSpinner = false
                    this.displaySignupComplete = true;
                } else {
                    this.hideLastSection = false;
                    this.submitting = false;
                    this.showSpinner = false;
                    console.log('error creating account ', resp);
                    if (resp.data.status == 'duplicate') {
                        this.$toast.add({ severity: 'error', summary: 'Our records indicate you already have an account.  Please sign in to access your account.', life: 3000, group: 'center' });
                    } else if (resp.data.status == 'username taken') {
                        const message = 'Username was taken. Please submit another one.';

                        this.$refs.submissionErrorModal.open(message);
                    } else {
                        this.$toast.add({ severity: 'error', summary: 'Sorry an unexpected error occurred. Please try again or contact support@invrs.com if the problem persists.', life: 3000, group: 'center' });
                    }
                }
            }).catch( error => {
                this.hideLastSection = false;
                this.submitting = false;
                this.showSpinner = false;
                console.error("Unexpected error during signup " + error);
                this.$toast.add({ severity: 'error', summary: 'Sorry an unexpected error occurred. Please try again or contact support@invrs.com if the problem persists.', life: 3000, group: 'center' });
            });
        },

        parseFullName(data) {
            // Splitting up inputted name by spaces
            const fullName = this.name.trim().split(' ');

            if (fullName.length == 1) {
                // Only first name
                data.fullName.first = fullName[0];
            } else if (fullName.length == 2) {
                // First and last name
                data.fullName.first = fullName[0];
                data.fullName.last = fullName[1];
            } else if (fullName.length == 3) {
                // First, middle, and last names
                let i = 0;

                for (let field in data.fullName) {
                    data.fullName[field] = fullName[i++];
                }
            }
        },
        emailConfirmed() {
            dataLayer.push({'event': 'signup-complete'}); // eslint-disable-line

            this.displaySignupComplete = false;
            window.location.href = APP_PAGE;
        },

        socialSignUpClick(socialAccountType) {
            SocialAuthService.socialAuth(socialAccountType, (socialAuthCode) => {
                if (socialAuthCode) {
                    this.isSocialSignUp = true;
                    this.socialAuthCode = socialAuthCode;
                    this.socialAccountType = socialAccountType;
                    sessionStorage.setItem('socialAuthCode', socialAuthCode);
                    sessionStorage.setItem('socialAccountType', socialAccountType);

                    this.routeProcessor(1);
                } else {
                    this.isSocialSignUp = false;
                    let msg = 'Sorry an expected error occurred during login.';
                    this.$toast.add({ severity: 'error', summary: msg, life: 2500, group: 'center' });
                    // show error
                }
            });
        },

        // Save any possible changes made on the current step before going to the next step
        nextStep() {
            let errors = [];

            /* - $touch() essentially checks if the fields are not validated and sees if it has errors
               - errors hold the existing errors each field currently has */
            if (this.$route.path === '/signup/1') {
                this.v$.password.$touch();
                this.v$.email.$touch();

                errors = this.v$.password.$errors.concat(this.v$.email.$errors);
            } else if (this.$route.path === '/signup/2') {
                this.v$.username.$touch();

                if (this.isSocialSignUp) {
                    errors = this.v$.username.$errors;

                    // console.log('in current step 1 error = ' + JSON.stringify(errors));
                    if (errors.length == 0) {
                        this.checkUserNameAvailable(this.confirmTerms);
                    }
                } else {
                    this.v$.name.$touch();
                    errors = this.v$.name.$errors.concat(this.v$.username.$errors);

                    // console.log('in current step 2 errors = ' + JSON.stringify(errors));
                    if (errors.length == 0) {
                    this.checkFullNameValid(()=>{
                            this.checkUserNameAvailable(this.confirmTerms);
                        });
                    }
                }
            }

            // If there are no errors, advance to next step
            if (errors.length == 0) {
                if (this.$route.path === '/signup/1') {
                    sessionStorage.setItem('email', this.email);
                    sessionStorage.setItem('password', this.password);
                } else if (this.$route.path === '/signup/2') {
                    sessionStorage.setItem('username', this.username ? this.username : '');
                    sessionStorage.setItem('name', this.name ? this.name : '');
                    sessionStorage.setItem('signupNonce', this.signupNonce);
                }

                this.routeProcessor(++this.currentStep);
            }
        },

        // Save any possible changes made on the current step before going to the previous step
        previousStep() {
            if (this.$route.path === '/signup/2') {
                sessionStorage.setItem('username', this.username ? this.username : '');
                sessionStorage.setItem('name', this.name ? this.name : '');
                sessionStorage.setItem('socialAuthCode', '');
                sessionStorage.setItem('socialAccountType', '');
                sessionStorage.setItem('signupNonce', '');
            }

            this.routeProcessor(--this.currentStep);
        },
        // Merely to keep track when the user types into any of the input fields for the first time, gets fired once due to the 'once' modifier
        typingIntoField(field) {
            if (field == 'email') {
                this.firstTimeTypingEmail = true;
            } else if (field == 'password') {
                this.firstTimeTypingPassword = true;
            } else if (field == 'name') {
                this.firstTimeTypingName = true;
            } else if (field == 'username') {
                this.firstTimeTypingUsername = true;
            }
        },

        resubmitUsername(username) {
            this.username = username;
            this.submitPackage();
        },

        /* STEP 3 METHODS */
        selectExperienceLevel(selectedTag) {
            this.experienceLevelTags.every(tag => {
                if(tag === selectedTag){
                    this.selectedExperienceLevelTag = tag; 
                    sessionStorage.setItem('selectedExperienceLevelTag', JSON.stringify(this.selectedExperienceLevelTag));
                    return false;
                }
                return true;
            });
        },

        /* VALIDATION METHODS */
        recaptchaVerified(t) {
            //console.log('captchaCall back response ' + t);
            UserService.verifyCaptcha(t, this.email, this.password).then((resp) => {
                if (resp.data.status == 'success') {
                    this.signupNonce = resp.data.signupNonce;
                    //console.log('captcha verified ' + JSON.stringify(this.signupNonce));
                    this.verified = true;
                } else {
                    console.log('captcha failed');
                }
            });
        },
        recaptchaExpired() {
            this.verified = false;
            this.$refs.vueRecaptcha.reset();
        },
        recaptchaFailed() {
            this.verified = false;
            this.$refs.vueRecaptcha.reset();
        },
        // Whenever we type something in the input fields, get rid of any current error messages that triggered
        resetError(field) {
            this.v$[field].$reset();
        },
    },

    validations() {
        return {
            password: {
                required: requiredCustomMessage,
                mediumOrStrong: helpers.withMessage('Weak password. Must contain at least 6 characters and at least 1 number.', CustomValidationUtils.mediumOrStrong),
            },
            email: {
                required: requiredCustomMessage,
                email: helpers.withMessage('Email address is not valid.', email),
            },
            username: {
                required: requiredCustomMessage,
                maxLength: helpers.withMessage('Must be 20 characters or less.', maxLength(20)),
                minLength: helpers.withMessage('Must be at least 6 characters long.', minLength(6)),
                noSpecialCharacters: helpers.withMessage('Should not contain any special characters.', CustomValidationUtils.noSpecialCharacters),
                noSpaces: helpers.withMessage('Should not contain any spaces, only underscores.', CustomValidationUtils.noSpaces),
                usernameTaken: helpers.withMessage('Username is not available.', helpers.withAsync(CustomValidationUtils.usernameTaken)),
            },
            name: {
                required: requiredCustomMessage,
                invalidName: helpers.withMessage('Name is either invalid or blocked.', helpers.withAsync(CustomValidationUtils.invalidFullName)),
            },
        };
    },
};
</script>

<style>
.p-component.p-password-panel {
    font-family: 'Trebuchet MS', 'Verdana';
}
.p-divider {
    padding-top: 15px;
    padding-bottom: 15px;
}
.p-divider-solid.p-divider-horizontal:before {
    border-top: 3px solid #f2f4fa;
}

.p-password-info {
    font-weight: bold;
    color: #32364e;
}
.p-password-meter {
    background-color: #f2f4fa;
}
.p-password-strength.weak {
    background-color: #ea5c5c;
}
.p-password-strength.medium {
    background-color: #fff2cc;
}
.p-password-strength.strong {
    background-color: #33cc99;
}

.terms-modal,
.signup-complete-modal {
    border-radius: 16px;
    overflow: hidden;
}

.terms-modal .p-dialog-title,
.signup-complete-modal .p-dialog-title {
    font-size: 24px !important;
    color: #32364e !important;
}

.terms-modal .p-dialog-content p,
.signup-complete-modal .p-dialog-content p {
    font-size: 16px;
    line-height: 1.2 !important;
}

.terms-modal .terms-button {
    font-size: 16px !important;
}

#terms-dialog > div.p-dialog-footer {
    text-align: center !important;
}
#terms-dialog > div.p-dialog-header > span.p-dialog-title {
    font-family: 'Trebuchet MS', 'Verdana' !important;
}

#signup-complete-dialog > div.p-dialog-footer {
    text-align: center !important;
}

#signup-complete-dialog > div.p-dialog-header > span.p-dialog-title {
    font-family: 'Trebuchet MS', 'Verdana' !important;
}
.signup-complete-button {
    padding: 8px 20px !important;
    font-size: 14px !important;
    color: #ffffff;
    background: #33cc99;
}
</style>
<style scoped>
* {
    font-family: 'Trebuchet MS', 'Verdana';
}

::v-deep(.p-message.p-message-warn) {
    width: 500px;
}

/* Shaking animation */
@keyframes shakeError {
    0% {
        transform: translateX(0);
    }
    15% {
        transform: translateX(0.375rem);
    }
    30% {
        transform: translateX(-0.375rem);
    }
    45% {
        transform: translateX(0.375rem);
    }
    60% {
        transform: translateX(-0.375rem);
    }
    75% {
        transform: translateX(0.375rem);
    }
    90% {
        transform: translateX(-0.375rem);
    }
    100% {
        transform: translateX(0);
    }
}

.signup-main-container {
    background-image: url(/assets/images/login-signup-graphic.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.signup-main-content {
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sign-up-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 80px;
    padding: 0 30px;
    position: fixed;
    width: 100%;
    z-index: 999;
}

.nav-image {
    height: 50px;
    cursor: pointer;
}

:deep(.login-button) {
    padding: 0;
}

.login-button {
    font-size: 24px;
    font-weight: bold;
    color: #32364e;
}

.login-button:enabled:hover {
    background: none;
    color: #33cc99;
}

.next-button,
.back-button,
.finish-button {
    margin-top: 10px;
    width: 100%;
    border-radius: 8px;
    font-size: 24px;
    padding: 10px 20px;
    justify-content: center;
}

.recaptcha-center {
    margin-bottom: 15px;
}

.next-button {
    background-color: #33cc99;
    color: white;
    border: 2px solid #33cc99;
}
.next-button:enabled:focus {
    box-shadow: none;
    border-color: #33cc99;
}

.back-button {
    background: none;
    color: #32364e;
    border: 2px solid #32364e;
}
.back-button:enabled:hover {
    background-color: #32364e;
    color: white;
    border-color: #32364e;
}
.back-button:enabled:focus {
    box-shadow: none;
    border-color: #32364e;
}

[class*='signup-container-'] {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.sign-up-container {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    width: 500px;
    padding: 20px;
    border-radius: 16px;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
}

.sign-up-title-container {
    display: flex;
    align-items: center;
    width: 100%;
}

.sign-up-title {
    font-size: 46px;
    color: #32364e;
    /* padding-bottom: 0.25em;
    line-height: 46px; */
}
.sign-up-title-2 {
    font-size: 36px;
    line-height: 26px;
    color: #32364e;
    padding-bottom: 0.25em;
    line-height: 36px;
}

.sign-up-subtext {
    font-size: 20px;
    color: #a6a6a6;
    margin-top: 10px;
    margin-bottom:10px;
}

.input-container {
    display: flex;
    flex-direction: column;
}
.input-section {
    margin-top: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.p-password {
    width: 100%;
}

.p-inputtext,
::v-deep(.p-inputtext) {
    width: 100%;
    font-size: 20px;
    border-radius: 8px;
    border: 2px solid #bfbfbf;
    background-color: #f2f4fa;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 20px;
    font-family: inherit;
}
::v-deep(.p-password-input) {
    padding-bottom: 15px;
    padding-top: 15px;
}
::v-deep(.pi.pi-eye) {
    padding-right: 5px;
}
::v-deep(.p-inputtext:enabled:focus),
.p-inputtext:enabled:focus {
    box-shadow: none;
    border-color: #33cc99;
}

.p-float-label {
    width: 100%;
}
.input-error {
    animation-name: shakeError;
    animation-fill-mode: forwards;
    animation-duration: 0.6s;
    animation-timing-function: ease-in-out;
}

.input-error .p-inputtext:hover,
::v-deep(.input-error .p-inputtext:hover),
.input-error .p-inputtext:enabled:focus,
::v-deep(.input-error .p-inputtext:enabled:focus),
.input-error .p-inputtext,
::v-deep(.input-error .p-inputtext) {
    border-color: #e63e3e;
}
.input-error .input-label {
    color: #e63e3e;
}
.error-message:nth-child(2) {
    padding-top: 10px;
}
.error-message {
    color: #e63e3e;
    padding-left: 10px;
}

.input-label {
    font-size: 20px;
    padding-left: 20px;
    line-height: 0.8;
}

.button-container {
    margin-top: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.action-button {
    font-size: 20px;
    background: #ffffff;
    color: #32364e;
    border: 2px solid #33cc99;
    padding: 10px 30px;
    border-radius: 8px;
}
.action-button.following,
.action-button.selected,
.action-button:hover {
    color: #ffffff;
    background: #33cc99;
}
.action-button:enabled:focus {
    box-shadow: none;
    border-color: #33cc99;
}

.social-button-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.social-button {
    margin-bottom: 25px;
    border-radius: 8px;
    font-size: 24px;
    width: 500px;
    padding: 10px 20px;
    color: black;
    border: 1px solid #f2f4fa;
    background: #fff;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
}
.social-button:hover {
    background-color: white;
    border-color: #32364e;
    color: black;
    cursor: pointer;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12), 0px 3px 5px rgba(0, 0, 0, 0.2);
    transition: box-shadow 0.3s ease-in-out;
}
.social-button-text {
    width: 100%;
}
.social-button-img {
    width: 1.5rem;
}

@media screen and (max-width: 1440px) and (min-width: 768px) {
    .signup-main-container {
        background-position-x: calc(100% + 150px);
    }

    .sign-up-title {
        font-size: calc(30px + (60 - 30) * ((100vw - 768px) / (1440 - 768)));
    }
    .sign-up-subtext {
        font-size: calc(16px + (20 - 16) * ((100vw - 768px) / (1440 - 768)));
    }

    .action-button {
        font-size: calc(16px + (20 - 16) * ((100vw - 768px) / (1440 - 768)));
    }
    .next-button,
    .back-button,
    .finish-button {
        font-size: calc(20px + (25 - 20) * ((100vw - 768px) / (1440 - 768)));
    }
}

@media (max-width: 976px) {
    .signup-main-container {
        background-position-x: calc(100% + 250px);
    }
}

@media (min-width: 761px) {
    .sign-up-header img {
        max-width: 250px;
    }
}

@media (max-width: 760px) {

    ::v-deep(.p-message.p-message-warn) {
        width: 90%;
    }

    .signup-main-container {
        background: none;
    }

    .signup-main-content {
        background: #f2f4fa;
    }

    .sign-up-title-container {
        position: relative;
    }

    .sign-up-title-container img {
        width: 90%;
        height: 135px;
        margin-left: auto;
    }

    .sign-up-header {
        padding: 0 20px;
        height: 60px;
    }

    .nav-image {
        height: 30px;
    }

    .login-button {
        font-size: 18px;
    }

    .sign-up-title {
        /* position: absolute; */
        font-size: 36px;
        font-weight: 400;
    }
    .sign-up-title-2 {
        font-size: 26px;
        font-weight: 400;
    }

    ::v-deep(.p-password-input),
    .p-inputtext {
        padding-bottom: 10px;
        padding-top: 10px;
    }

    .p-inputtext,
    ::v-deep(.p-inputtext) {
        font-size: 18px;
    }

    .login-button {
        padding: none;
    }

    .next-button,
    .back-button {
        font-size: 20px;
    }

    :deep(.p-dialog) {
        border-radius: 16px;
    }

    .sign-up-title-center,
    .sign-up-subtext-center {
        text-align: center;
    }
    .sign-up-subtext {
        width: 100%;
        color: #bfbfbf;
        font-size: 16px;
    }
    .action-button {
        padding: 8px 20px;
        font-size: 14px;
    }
    .sign-up-container {
        padding: 15px;
        width: 90%;
        max-width: 500px;
    }
    .social-button {
        width: 90%;
        max-width: 500px;
    }
    .sign-up-header img {
        max-width: 150px;
    }

    .button-container {
        padding-bottom: 0;
    }

    .signup-complete-modal {
        width: 100%;
    }
}

@media (max-width: 432px) {
    .sign-up-title {
        font-size: 26px;
    }
}

@media (max-width: 280px) {

    .sign-up-title {
        font-size: 18px;
    }
    .sign-up-title-container img {
        width: 95px;
        height: 75px;
    }
}

.terms-modal {
    border-radius: 16px;
}

.terms-button {
    padding: 8px 20px !important;
    font-size: 14px !important;
    color: #ffffff;
    background: #33cc99;
}

.terms-button.reject {
    border-color: #e63e3e !important;
    background: #ffffff;
    color: #32364e;
}

.terms-button.reject:hover,
.terms-button.reject:focus {
    background: #e63e3e !important;
    color: #ffffff !important;
}
</style>
<style scoped>

.tags-container {
    align-items: center;
    display: flex;
    justify-content: space-around;
    margin-top: 15px;
}

.tag-button {
    font-size: 18px;
    background: #ffffff;
    color: #32364e;
    border: 2px solid #33cc99;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    /* padding: 10px 30px; */
}

.tag-button.selected,
.tag-button:hover {
    color: #ffffff;
    background: #33cc99;
}

.tag-button:enabled:focus {
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    border-color: #33cc99;
}

@media (max-width: 760px) {
    .tag-button {
        font-size: 15px;
        padding: 10px 10px;
    }
}

</style>

<style scoped>
/** for competition */
section {
  padding-left: 3.6rem;
  padding-right: 3.6rem;
  background-color: #32364e;
  border-radius: 16px;
  /* transform: skewY(3deg); */
  box-shadow: inset 4.8rem 4.8rem 9.6rem #2b2e42,
    inset -4.8rem -4.8rem 9.6rem #3a3e5a;

}

.container {
  /* transform: skewY(-3deg); */
  /* width: 100%; */
}

.section-header {
  display: flex;
  flex-direction: column;
  text-align:center;
}

.subheading {
  color: #33cc99;
}

.heading-secondary {
  color: #fff;
  font-size: 1.8rem;
  padding-top:.5rem; 
  margin-bottom: 0;
}

.heading-description {
  /* line-height: 3.5rem; */
  /* width: 60%; */
  color: #f2f4fa;
  /* margin-bottom: 4.8rem; */
  padding-bottom:.5rem;
}



.section-data {
  transition: all 0.3s ease-in;
}

.hidden {
  transform: skewY(-3deg);
  transform: translateY(4.8rem);
  opacity: 0;
}

/* **************************** */
/* BELOW 1360px (Small desktop/laptop) */
/* **************************** */
@media (max-width: 85em) {
  
}

/* **************************** */
/* BELOW 800px (tablet) */
/* **************************** */
@media (max-width: 50em) {
  

  .heading-description {
    width: 100%;
  }

  .slide-icon {
    width: 7%;
    margin-right: 1.6rem;
  }
}

/* **************************** */
/* BELOW 592px (mobile) */
/* **************************** */
@media (max-width: 37em) {
  
}

/* **************************** */
/* BELOW 496px (mobile) */
/* **************************** */
@media (max-width: 31em) {
  
}

/* **************************** */
/* BELOW 432px (mobile) */
/* **************************** */
@media (max-width: 27em) {
  
}
</style>